import React from 'react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

const Wrapper = styled.div`
  font-size: 18px;
  width: 24px;
  cursor: pointer;
  > div {
    background: currentColor;
    height: 2px;
    margin-bottom: 6px;
    &:nth-child(1) {
      transition: transform 150ms linear;
      transform: ${ifProp('open', `translateY(8px) rotate(-45deg)`, '')};
    }
    &:nth-child(2) {
      transition: opacity 150ms linear;
      opacity: ${ifProp('open', 0, 1)};
    }
    &:nth-child(3) {
      transition: transform 150ms linear;
      transform: ${ifProp('open', `translateY(-8px) rotate(45deg)`, '')};
      margin-bottom: 0;
    }
  }
`

const Hamburger = ({ open, className, onClick }) => (
  <Wrapper open={open} className={className} onClick={onClick}>
    <div />
    <div />
    <div />
  </Wrapper>
)

export default Hamburger
