import React from 'react'
import styled from 'styled-components'

const Youtube = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="45 45 210 210" {...props}>
    <g transform="matrix(5.9026317,0,0,5.9026317,521.39665,-711.31763)">
      <path
        d="m 149.9375,79.222656 c 0,0 -63.218849,5.9e-5 -79.085938,4.123047 -8.495787,2.373802 -15.491408,9.3695 -17.865234,17.990237 -4.122953,15.8671 -4.123047,48.72656 -4.123047,48.72656 0,0 9.4e-5,32.9842 4.123047,48.60156 2.373826,8.62062 9.244506,15.49138 17.865234,17.86524 15.99203,4.24788 79.085938,4.24804 79.085938,4.24804 0,0 63.34418,-5e-5 79.21094,-4.12304 8.62079,-2.37381 15.49133,-9.11966 17.74023,-17.86524 4.24793,-15.74232 4.24805,-48.60156 4.24805,-48.60156 0,0 0.12484,-32.98446 -4.24805,-48.85156 -2.2489,-8.620737 -9.11944,-15.491334 -17.74023,-17.740237 -15.86676,-4.372847 -79.21094,-4.373047 -79.21094,-4.373047 z m -20.11523,40.480464 52.59961,30.35938 -52.59961,30.23438 0,-60.59376 z"
        transform="matrix(0.16941596,0,0,0.16941596,-88.332912,120.50856)"
      />
    </g>
  </svg>
)

const Facebook = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-30 -30 572 572" {...props}>
    <path d="M288,176v-64c0-17.664,14.336-32,32-32h32V0h-64c-53.024,0-96,42.976-96,96v80h-64v80h64v256h96V256h64l32-80H288z" />
  </svg>
)

const Instagram = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 74 74" {...props}>
    <path
      d="M32,6.6c8.3,0,9.3,0,12.5,0.2c3,0.1,4.7,0.6,5.8,1.1c1.4,0.6,2.5,1.2,3.6,2.3c1.1,1.1,1.8,2.1,2.3,3.6
  c0.4,1.1,0.9,2.7,1.1,5.8c0.1,3.3,0.2,4.2,0.2,12.5s0,9.3-0.2,12.5c-0.1,3-0.6,4.7-1.1,5.8c-0.6,1.4-1.2,2.5-2.3,3.6
  c-1.1,1.1-2.1,1.8-3.6,2.3c-1.1,0.4-2.7,0.9-5.8,1.1c-3.3,0.1-4.2,0.2-12.5,0.2s-9.3,0-12.5-0.2c-3-0.1-4.7-0.6-5.8-1.1
  c-1.4-0.6-2.5-1.2-3.6-2.3c-1.1-1.1-1.8-2.1-2.3-3.6c-0.4-1.1-0.9-2.7-1.1-5.8C6.6,41.3,6.6,40.3,6.6,32s0-9.3,0.2-12.5
  c0.1-3,0.6-4.7,1.1-5.8c0.6-1.4,1.2-2.5,2.3-3.6c1.1-1.1,2.1-1.8,3.6-2.3c1.1-0.4,2.7-0.9,5.8-1.1C22.7,6.6,23.7,6.6,32,6.6 M32,1
  c-8.4,0-9.5,0-12.8,0.2c-3.3,0.2-5.6,0.7-7.5,1.4c-2,0.8-3.8,1.9-5.5,3.6c-1.7,1.7-2.8,3.5-3.6,5.5c-0.8,2-1.3,4.2-1.4,7.5
  C1,22.5,1,23.6,1,32c0,8.4,0,9.5,0.2,12.8c0.2,3.3,0.7,5.6,1.4,7.5c0.8,2,1.9,3.8,3.6,5.5c1.7,1.7,3.5,2.8,5.5,3.6
  c2,0.8,4.2,1.3,7.5,1.4C22.5,63,23.6,63,32,63s9.5,0,12.8-0.2c3.3-0.2,5.6-0.7,7.5-1.4c2-0.8,3.8-1.9,5.5-3.6
  c1.7-1.7,2.8-3.5,3.6-5.5c0.8-2,1.3-4.2,1.4-7.5C63,41.5,63,40.4,63,32s0-9.5-0.2-12.8c-0.2-3.3-0.7-5.6-1.4-7.5
  c-0.8-2-1.9-3.8-3.6-5.5c-1.7-1.7-3.5-2.8-5.5-3.6c-2-0.8-4.2-1.3-7.5-1.4C41.5,1,40.4,1,32,1L32,1z"
    />
    <path
      d="M32,16.1c-8.8,0-15.9,7.1-15.9,15.9S23.2,47.9,32,47.9S47.9,40.8,47.9,32S40.8,16.1,32,16.1z M32,42.3
  c-5.7,0-10.3-4.6-10.3-10.3c0-5.7,4.6-10.3,10.3-10.3c5.7,0,10.3,4.6,10.3,10.3C42.3,37.7,37.7,42.3,32,42.3z"
    />
    <circle cx="48.5" cy="15.5" r="3.7" />
  </svg>
)

const MapMarker = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" {...props}>
    <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" />
  </svg>
)

const Social = ({ type, children, ...props }) =>
  React.createElement(
    {
      Instagram,
      Facebook,
      Youtube,
      MapMarker,
    }[type],
    props,
    children
  )

export default styled(Social)`
  display: block;
  fill: currentColor;
`
