import React from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'

import Block from '../Block'

const Wrapper = styled.div`
  text-align: ${prop('align', 'inherit')};
`

const Module = ({ module, children, eager, className }) => (
  <Block
    align={module.align}
    image={module.image && module.image.asset._ref}
    type={module.variant}
    eager={eager}
  >
    <Wrapper align={module.align} className={className}>
      {children}
    </Wrapper>
  </Block>
)

export default Module
