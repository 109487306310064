import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { rem } from 'polished'
import { media } from '../../theme'

import BlockContent from '../BlockContent'
import Button from '../Button'
import Module from './Module'

const StyledButton = styled(Button)`
  color: inherit;
  border: 1px solid currentColor;
`

const StyledModule = styled(Module)`
  ${media('sm')`
    width: ${(1 / 2) * 100}%;
    margin-left: ${ifProp({ align: 'left' }, 0, 'auto')};
    margin-right: ${ifProp({ align: 'right' }, 0, 'auto')};
  `}
  h2 {
    line-height: 1.3;
    margin-bottom: ${rem(32)};
  }
  p {
    font-size: ${rem(20)};
  }
`

const script = `
(function(d, s, id){
  var js, fjs = d.getElementById('agendo-widget-loader');if (d.getElementById(id)) return;js = d.createElement(s);
  js.id = id;js.src = fjs.getAttribute('data-domain');fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'agendo-widget-id'));
`

const toggleWidget = () => {
  if (document.Agendo) {
    document.Agendo.toggleWidget()
  }
}

const BookingBannerModule = ({ module }) => (
  <StyledModule module={module} align={module.align}>
    <Helmet>
      <script
        data-domain="https://booking.agendoapp.com/loader.js"
        id="agendo-widget-loader"
        data-element=".agendo-button"
        data-language="sv"
      >
        {script}
      </script>
    </Helmet>
    <h2>{module.title}</h2>
    {module.body && <BlockContent blocks={module.body} />}
    <StyledButton inverted onClick={toggleWidget}>
      {module.cta || 'Boka'}
    </StyledButton>
  </StyledModule>
)

export default BookingBannerModule
