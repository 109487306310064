import React from 'react'

import Image from '../../Image'

const WrappedImage = ({ node }) => (
  <figure>
    <Image image={node.image.asset._ref} alt={node.alt} title={node.title} />
    {node.caption && <figcaption>{node.caption}</figcaption>}
  </figure>
)

export default WrappedImage
