import React from 'react'
import Select from './Select'

const getValue = (item, isMulti) =>
  isMulti ? (item || []).map(({ value }) => value) : (item || {}).value

const findOption = options => v => options.find(({ value }) => value === v)
const getSelected = (value, options, isMulti) =>
  isMulti ? value.map(findOption(options)) : findOption(options)(value)

const FormikSelect = ({ className, options, name, isMulti, ...field }) => (
  <Select
    isMulti={isMulti}
    className={className}
    options={options}
    value={getSelected(field.value, options, isMulti)}
    onChange={item =>
      field.onChange({ target: { name, value: getValue(item, isMulti) } })
    }
    onBlur={() => field.onBlur({ target: { name } })}
  />
)

export default FormikSelect
