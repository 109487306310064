import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { ifNotProp } from 'styled-tools'
import { rem } from 'polished'

import { media } from '../../theme'
import Block from '../Block'
import Logo from '../Logo'
import Hamburger from '../Hamburger'
import Menu from '../Menu'

const StyledHeader = styled(Block)`
  position: ${ifNotProp({type: 'transparent' }, 'relative', 'absolute')};
  z-index: 3;
  padding: ${rem(18)} ${rem(12)} ${rem(6)};
  ${media('sm')`
    padding: ${rem(20)};
  `}
  width: 100%;
  a {
    color: currentColor;
  }
`

const StyledLogo = styled(Logo)`
  position: relative;
  z-index: 3;
  height: ${rem(64)};
  ${media('sm')`
    height: ${rem(100)};
  `}
  max-width: 100%;
  margin: 0 auto;
  ${media('sm')`
    margin: 0;
  `}
`

const StyledHamburger = styled(Hamburger)`
  position: absolute;
  z-index: 1;
  top: ${rem(8)};
  right: ${rem(0)};
  padding: 12px;
  width: 48px;
  ${media('md')`
    display: none;
  `}
`

const Header = ({ type }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <StyledHeader component="header" type={type || 'transparent'}>
      <Link to="/">
        <StyledLogo inverted tagline />
      </Link>
      <StyledHamburger
        open={menuOpen}
        onClick={() => setMenuOpen(open => !open)}
      />
      <Menu open={menuOpen} />
    </StyledHeader>
  )
}

export default Header
