import { rem, tint } from 'polished'

const colors = {
  black: '#000000',
  white: '#FFFFFF',
  olive: '#688155',
  red: '#FF6644',
}

const contextualColors = {
  primary: colors.olive,
  primaryText: tint(0.15, colors.black),
  dark: tint(0.15, colors.black),
  medium: tint(0.5, colors.black),
  mediumLight: tint(0.8, colors.black),
  light: tint(0.95, colors.black),
  error: colors.red,
}

export default {
  typography: {
    fontFamily: `Open Sans, sans-serif`,
    fontSize: rem(14),
  },
  sizes: {
    sm: '100%',
    md: '1024px',
    lg: '1024px',
  },
  breakpoints: {
    sm: '640px',
    md: '1024px',
    lg: '1280px',
  },
  colors: {
    ...colors,
    ...contextualColors,
  },
}
