import React from 'react'
import styled from 'styled-components'
import { switchProp, withProp } from 'styled-tools'
import { rem, transparentize } from 'polished'
import Image from '../Image'
import { Container } from '../Grid'
import { media } from '../../theme'
import colorsByType from './colorsByType'

const Wrapper = styled.section`
  position: relative;
`

const MiddleWrapper = styled.div`
  position: relative;
  ${withProp('type', colorsByType)};
  padding: ${rem(36)} ${rem(21)};
  ${media('sm')`
    padding: ${rem(56)} ${rem(28)};
  `}
`

const InnerWrapper = styled(Container)`
  position: relative;
  z-index: 1;
`

const Glass = styled(MiddleWrapper)`
  position: relative;
  background: ${switchProp(
    'type',
    {
      transparent: 'transparent',
      white: withProp('theme.colors.white', transparentize(0.6)),
      light: withProp('theme.colors.light', transparentize(0.1)),
      dark: withProp('theme.colors.dark', transparentize(0.2)),
    },
    'transparent'
  )};
`

const StyledImage = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Block = ({
  className,
  children,
  image,
  type,
  glass,
  component,
  eager,
}) => {
  const Container = image ? Glass : MiddleWrapper
  return (
    <Wrapper as={component}>
      {image && (
        <StyledImage image={image} loading={eager ? 'eager' : 'lazy'} />
      )}
      <Container type={type} className={className}>
        <InnerWrapper>{children}</InnerWrapper>
      </Container>
    </Wrapper>
  )
}

export default Block
