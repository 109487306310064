import React from 'react'
import BaseBlockContent from '@sanity/block-content-to-react'

import serializers from './serializers'

const MyBlockContent = props => (
  <BaseBlockContent
    renderContainerOnSingleChild
    serializers={serializers}
    {...props}
  />
)

export default MyBlockContent
