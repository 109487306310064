import dateFormatter from './dateFormatter'
const weekdayFormat = dateFormatter('w')

const getFirstOfMonth = d =>
  new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), 1))
const getLastOfMonth = d =>
  new Date(Date.UTC(d.getUTCFullYear(), d.getMonth() + 1, 0))
const getFirstOfWeek = d =>
  new Date(
    Date.UTC(
      d.getUTCFullYear(),
      d.getUTCMonth(),
      d.getUTCDate() - ((d.getUTCDay() + 6) % 7)
    )
  )
const getLastOfWeek = d =>
  new Date(
    Date.UTC(
      d.getUTCFullYear(),
      d.getUTCMonth(),
      d.getUTCDate() + ((7 - d.getUTCDay()) % 7)
    )
  )
const getDaysBetween = (a, b) =>
  (b.getTime() - a.getTime()) / (24 * 3600 * 1000)

const getWeekdays = startDate =>
  [...Array(7).keys()].map(i => {
    const d = getFirstOfWeek(startDate)
    d.setDate(d.getDate() + i)
    return weekdayFormat(d)
  })

const getDays = startDate => {
  const first = getFirstOfWeek(getFirstOfMonth(startDate))
  const last = getLastOfWeek(getLastOfMonth(startDate))
  const interval = getDaysBetween(first, last) + 1
  return [...Array(interval).keys()].map(
    i =>
      new Date(
        Date.UTC(
          first.getUTCFullYear(),
          first.getUTCMonth(),
          first.getUTCDate() + i
        )
      )
  )
}

const getToday = () => {
  const d = new Date()
  d.setUTCHours(0, 0, 0, 0)
  return d
}

const stepMonth = amount => d => {
  const date = new Date(d)
  date.setUTCMonth(d.getUTCMonth() + amount)
  return date
}

const previousMonth = stepMonth(-1)
const nextMonth = stepMonth(1)

export {
  getFirstOfMonth,
  getDays,
  getWeekdays,
  getToday,
  previousMonth,
  nextMonth,
}
