import { createGlobalStyle } from 'styled-components'
import { theme } from 'styled-tools'
import { normalize, rem } from 'polished'

export default createGlobalStyle`
  ${normalize()};

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  :root {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.015em;
    line-height: 1;
    color: ${theme('colors.primaryText')};
    overflow-x: hidden;
    background: ${theme('colors.dark')};
  }

  body {
    margin: 0;
    font-size: ${rem(16)};
    text-align: center;
    overflow-x: hidden;
    background: ${theme('colors.light')};
  }

  h1 {
    font-size: ${rem(44)};
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.07em;
    line-height: 1;
    margin: 0;
  }

  h2 {
    font-size: ${rem(32)};
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.05em;
    line-height: 1;
    margin: 0;
  }

  h3 {
    font-size: ${rem(22)};
    font-weight: 400;
    line-height: 1;
    margin: 0;
  }

  h4 {
    font-size: ${rem(18)};
    font-weight: 400;
    line-height: 1;
    margin: 0;
  }

  p {
    font-size: ${rem(16)};
    font-weight: 300;
    line-height: 1.5;
    margin: ${rem(12)} 0;
  }

  blockquote {
    font-style: italic;
    font-family: 'Amiri', sans-serif;
    line-height: 1.3;
  }

  figcaption {
    font-style: italic;
    line-height: 1.5;
  }

  strong {
    font-weight: 600;
  }

  address {
    font-style: normal;
  }

  a {
    color: ${theme('colors.primary')};
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }

  button {
    border-radius: ${rem(4)};
    text-transform: uppercase;
    padding: 0.9em 1.6em;
    cursor: pointer;
  }

  label {
    display: block;
    width: 100%;
    text-align: left;
    font-size: ${rem(14)};
    margin: 0 0 ${rem(4)} ${rem(2)};
    @media (min-width: ${theme('breakpoints.sm')}) {
      margin-bottom: ${rem(6)};
    }
  }

  input {
    display: block;
    color: ${theme('colors.primaryText')};
    width: 100%;
    text-align: left;
    line-height: 1.3;
    padding: ${rem(8)};
    border: 1px solid ${theme('colors.mediumLight')};
    border-radius: ${rem(2)};
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &[type='text'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }

  textarea {
    display: block;
    color: ${theme('colors.primaryText')};
    width: 100%;
    text-align: left;
    padding: ${rem(8)};
    border: 1px solid ${theme('colors.mediumLight')};
    border-radius: ${rem(2)};
    box-shadow: none;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }
`
