import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import Module from './Module'

const Title = styled.h1`
  margin: ${rem(60)} 0;
  line-height: 1.3;
  white-space: pre-wrap;
`

const TitleModule = ({ module, className }) => (
  <Module module={module} className={className} eager>
    <Title>{module.title}</Title>
  </Module>
)

export default TitleModule
