import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { rem } from 'polished'
import { useField } from 'formik'

import Select from '../Select'
import TextArea from '../TextArea'
import Calendar from '../Calendar'

const Wrapper = styled.div`
  & + & {
    margin-top: ${rem(16)};
  }
`

const Label = styled.label`
  line-height: 1.5;
`

const ErrorMessage = styled.p`
  text-align: left;
  margin-top: 0;
  margin-left: ${rem(6)};
  font-size: ${rem(14)};
  color: ${theme('colors.error')};
`

const spaceCase = str =>
  str[0].toUpperCase() +
  str.slice(1).replace(/([a-z])([A-Z])/g, (_, m, n) => [m, n].join(' '))

const getType = type =>
  ['int', 'float'].includes(type) ? 'number' : type || 'text'

const FormField = ({
  className,
  name,
  validate,
  label: optionalLabel,
  type,
  placeholder,
  disabled,
  options,
  ...props
}) => {
  const [field, meta] = useField({ name, validate })
  const label = optionalLabel ?? spaceCase(name)
  return (
    <Wrapper className={className}>
      <Label htmlFor={name}>{label}</Label>
      {type === 'select' ? (
        <Select
          options={options}
          {...field}
          isMulti={Array.isArray(meta.initialValue)}
        />
      ) : type === 'date' ? (
        <Calendar {...field} {...props} />
      ) : type === 'textArea' ? (
        <TextArea name={name} {...field} />
      ) : (
        <input
          type={getType(type)}
          pattern={type === 'int' ? '\\d*' : null}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          {...field}
        />
      )}
      {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
    </Wrapper>
  )
}
export default FormField
