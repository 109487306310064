import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { withProp } from 'styled-tools'
import { rem, transparentize } from 'polished'
import { Formik, Form } from 'formik'

import Module from './Module'
import FormField from '../FormField'
import Button from '../Button'
import Loader from '../Loader'
import dateFormatter from '../Calendar/dateFormatter'

const dateFormat = dateFormatter('YmD')

const StyledModule = styled(Module)`
  max-width: ${rem(500)};
  margin: 0 auto;

  h2 {
    margin-bottom: ${rem(32)};
    line-height: 1.3;
  }
`

const StyledForm = styled(Form)`
  max-width: ${rem(500)};
  margin: 0 auto;
  position: relative;
`

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${withProp('theme.colors.light', transparentize(0.2))};
  display: flex;
`

const StyledLoader = styled(Loader)`
  width: 100%;
`

const StyledP = styled.p`
  margin-bottom: ${rem(32)};
`

const initialValues = {
  name: '',
  email: '',
  item: null,
  date: null,
  phone: '',
  bike: '',
  description: '',
}

const useServiceItemOptions = () => {
  const data = useStaticQuery(graphql`
    query ServiceItemOptionsQuery {
      serviceItems: allSanityServiceItem(
        sort: { order: DESC, fields: _updatedAt }
        filter: { slotWeight: { gt: 0 } }
      ) {
        edges {
          node {
            price
            title
            type
            slotWeight
          }
        }
      }
    }
  `)

  return data.serviceItems.edges.map(({ node }) => ({
    value: {
      name: node.title,
      type: node.type,
      slotWeight: node.slotWeight,
    },
    label: node.title + (node.price ? ` (${node.price})` : ''),
  }))
}

const useServiceItemAvailability = () => {
  const [state, setState] = useState({ loading: true, data: {} })
  useEffect(() => {
    fetch('/.netlify/functions/bookings')
      .then(response => response.json())
      .then(data => setState({ loading: false, data }))
  }, [])
  return state
}

const send = data =>
  fetch('/.netlify/functions/bookings', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(response => {
    if (!response.ok) {
      throw Error('Something went wrong')
    }
  })

const required = value => (!value ? 'Obligatoriskt fält' : null)

const ServiceBookerModule = ({ module }) => {
  const [done, setDone] = useState(false)
  const options = useServiceItemOptions()
  const { loading, data: availability } = useServiceItemAvailability()
  return (
    <StyledModule module={module} align={module.align}>
      <h2>{module.title || 'Jag vill ha service'}</h2>
      {module.description && <StyledP>{module.description}</StyledP>}
      {done ? (
        <div>
          <h3>Tack för din förfrågan</h3>
          <p>
            Vi skickar bekräftelse och möjligtvis lite följdfrågor på epost så
            fort vi kan.
          </p>
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={async (data, { setSubmitting, resetForm }) => {
            try {
              window.dataLayer.push({
                event: 'serviceRequest',
                data,
              })
              await send(data)
              setSubmitting(false)
              setDone(true)
            } catch (e) {
              console.error('Something went wrong', e)
            }
          }}
        >
          {({ isSubmitting, values, errors }) => (
            <StyledForm>
              <FormField
                name="bike"
                label="Cykel (märke, modell och årsmodell) – Om du är osäker på dessa uppgifter, hör av dig först så
kan vi hjälpa till bättre"
                type="text"
              />
              <FormField
                name="item"
                label="Service"
                type="select"
                options={options}
              />
              <FormField
                name="description"
                label="Beskrivning (beskriv så tydligt du kan så kan vi hjälpa till bättre)"
                type="textArea"
              />
              <FormField name="name" label="Namn" />
              <FormField
                name="email"
                label="Epost"
                type="email"
                validate={required}
              />
              <FormField name="phone" label="Telefon" type="tel" />
              <FormField
                name="date"
                label="Datum"
                type="date"
                isDisabled={day =>
                  [0, 1, 6].includes(day.getDay()) ||
                  day.getTime() < Date.now() ||
                  availability[dateFormat(day)] >= 1
                }
                validate={required}
              />
              <Button disabled={isSubmitting} type="submit">
                Skicka
              </Button>
              {loading && (
                <LoaderWrapper>
                  <StyledLoader />
                </LoaderWrapper>
              )}
            </StyledForm>
          )}
        </Formik>
      )}
    </StyledModule>
  )
}

export default ServiceBookerModule
