import React from 'react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { rem } from 'polished'
import { media } from '../../theme'

import BlockContent from '../BlockContent'
import Module from './Module'
import InternalLink from '../InternalLink'

const Button = styled(InternalLink)`
  display: inline-block;
  color: inherit;
  border: 1px solid currentColor;
  border-radius: 4px;
  padding: ${rem(18)} ${rem(28)};
  :hover {
    text-decoration: none;
  }
  margin: ${rem(32)} 0 ${rem(16)};
  ${media('sm')`
    margin-bottom: 0;
  `}
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const StyledModule = styled(Module)`
  ${media('sm')`
    width: ${(1 / 2) * 100}%;
    margin-left: ${ifProp({ align: 'left' }, 0, 'auto')};
    margin-right: ${ifProp({ align: 'right' }, 0, 'auto')};
  `}
  h2 {
    line-height: 1.3;
    margin-bottom: ${rem(32)};
  }
  p {
    font-size: ${rem(20)};
  }
`

const BannerModule = ({ module }) => (
  <StyledModule module={module} align={module.align}>
    <h2>{module.title}</h2>
    {module.body && <BlockContent blocks={module.body} />}
    {module.link?.page && (
      <Button to={module.link.page._ref}>{module.link.label}</Button>
    )}
  </StyledModule>
)

export default BannerModule
