import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'
import { rem } from 'polished'
import dateFormatter from '../../Calendar/dateFormatter'
const timeFormat = dateFormatter('HN')

const EventList = styled.ul`
  background: ${theme('colors.white')};
  margin: ${rem(12)} 0;
  text-align: left;
  border-radius: ${rem(2)};
  padding: ${rem(3)} 0;
`

const Wrapper = styled.li`
  padding: ${rem(12)} ${rem(6)};
  margin: ${rem(6)} ${rem(12)};
  border-bottom: 1px solid ${theme('colors.light')};
  :last-child {
    border: none;
  }
`

const Title = styled.h3`
  color: ${theme('colors.primary')};
  font-size: ${rem(24)};
`
const Subtitle = styled.p`
  font-size: ${rem(12)};
  margin: ${rem(3)} 0;
`

const Description = styled.div`
  line-height: 1.4;
  ul {
    margin: ${rem(6)} 0;
    padding-left: 1.5em;
    li {
      list-style: square;
      margin: ${rem(3)};
    }
  }
`

const EventItem = ({ event }) => (
  <Wrapper>
    <Title>{event.summary}</Title>
    <Subtitle>
      {timeFormat(new Date(event.start))} – {timeFormat(new Date(event.end))}
    </Subtitle>
    <Description dangerouslySetInnerHTML={{ __html: event.description }} />
  </Wrapper>
)

const Events = ({ events }) => (
  <EventList>
    {events.map(event => (
      <EventItem event={event} key={event.id} />
    ))}
  </EventList>
)

export default Events
