import React from 'react'

import InternalLink from '../../InternalLink'

export const internalLink = ({ mark, children }) => (
  <InternalLink to={mark.page._ref}>{children}</InternalLink>
)

export const link = ({ mark, children }) => (
  <a href={mark.href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
)
