import React from 'react'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { rem } from 'polished'
import { media } from '../../theme'
import Module from './Module'

const specOrder = {
  general: ['color', 'size', 'type', 'category'],
  frame: ['fork', 'shock', 'seatpost', 'seat'],
  powertrain: [
    'derailleurs',
    'shifters',
    'crankset',
    'cassette',
    'chain',
    'brakes',
  ],
  wheels: ['wheels', 'tyreFront', 'tyreRear'],
  electric: ['motor', 'battery'],
  misc: ['misc'],
}

const labels = {
  general: 'Allmänt',
  frame: 'Ram',
  powertrain: 'Drivlina',
  electric: 'Elektriskt',
  misc: 'Övrigt',
  size: 'Storlek',
  type: 'Typ',
  category: 'Kategori',
  color: 'Färg',
  fork: 'Gaffel',
  shock: 'Dämpare',
  derailleurs: 'Växlar',
  shifters: 'Växelreglage',
  crankset: 'Vevparti',
  cassette: 'Kassett',
  chain: 'Kedja',
  brakes: 'Bromsar',
  wheels: 'Hjul',
  tyreFront: 'Däck fram',
  tyreRear: 'Däck bak',
  seatpost: 'Sadelstolpe',
  seat: 'Sadel',
  motor: 'Motor',
  battery: 'Batteri',
  misc: 'Övrigt',
  price: 'Pris',
}

const StyledModule = styled(Module)`
  h2 {
    text-align: center;
  }
  h3 {
    margin: ${rem(36)} ${rem(4)} ${rem(12)};
    color: ${theme('colors.primary')};
  }
  ul ul li {
    display: flex;
    border-bottom: 1px solid ${theme('colors.mediumLight')};
    :first-child {
      border-top: 1px solid ${theme('colors.mediumLight')};
    }
    > p {
      flex-grow: 1;
      text-align: right;
      margin: ${rem(8)} ${rem(4)};
      width: 60%;
      :first-child {
        font-weight: 600;
        text-align: left;
        width: 40%;
      }
    }
  }
  ${media('sm')`
    padding: 0 ${rem(64)};
  `}
`

const BikeSpecificationModule = ({ module }) => (
  <StyledModule module={module} align={module.align}>
    <h2>{module.title}</h2>
    <ul>
      {Object.entries(specOrder).map(([category, specs]) => (
        <li key={category}>
          <h3>{labels[category]}</h3>
          <ul>
            {specs.map(spec => (
              <li key={spec}>
                <p>{labels[spec]}</p>
                <p>{module.specifications && module.specifications[spec] || '–'}</p>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  </StyledModule>
)

export default BikeSpecificationModule
