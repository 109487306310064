import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import { media } from '../../theme'
import { Social } from '../Logo'

const StyledSocial = styled(Social)`
  height: ${rem(30)};
  color: inherit;
`

const Wrapper = styled.div`
  margin: ${rem(16)} 0 ${rem(48)};
  ${media('sm')`
    margin-top: 0;
  `}
  display: flex;
  justify-content: center;
  > * {
    margin: 0 ${rem(24)};
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  }
  a {
    color: inherit;
  }
`

const socials = [
  {
    id: 'MapMarker',
    link: 'https://goo.gl/maps/t8eGiWK2NZjWXZc98/',
  },
  {
    id: 'Facebook',
    link: 'https://www.facebook.com/Catu-bikes-107416547407859/',
  },
  {
    id: 'Instagram',
    link: 'https://www.instagram.com/catubikes/',
  },
]

const SocialWrapper = () => (
  <Wrapper>
    {socials.map(social => (
      <a
        key={social.id}
        href={social.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledSocial type={social.id} />
      </a>
    ))}
  </Wrapper>
)

export default SocialWrapper
