import React from 'react'

import BannerModule from './BannerModule'
import BookingBannerModule from './BookingBannerModule'
import TitleModule from './TitleModule'
import TextModule from './TextModule'
import ServiceItemsModule from './ServiceItemsModule'
import ServiceBookerModule from './ServiceBookerModule'
import BikeSpecificationModule from './BikeSpecificationModule'
import BikeListModule from './BikeListModule'
import CalendarModule from './CalendarModule'

const modules = {
  bannerModule: BannerModule,
  titleModule: TitleModule,
  textModule: TextModule,
  serviceItemsModule: ServiceItemsModule,
  serviceBookerModule: ServiceBookerModule,
  bikeSpecificationModule: BikeSpecificationModule,
  bikeListModule: BikeListModule,
  bookingBannerModule: BookingBannerModule,
  calendarModule: CalendarModule,
}

const Module = props => {
  const module = modules[props.module._type]
  if (!module) {
    console.error(`No module of type ${props.module._type} exists`)
    return null
  }
  return React.createElement(module, props)
}

// const BlockModule = ({ module, first }) => {
//   return (
//     <Module module={module} first={first}>
//       {module.body && (
//         <BlockContent blocks={module.body} align={module.align} />
//       )}
//     </Module>
//   )
// }

export default Module
