import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { theme, ifNotProp, ifProp, withProp } from 'styled-tools'
import { rem, tint, lighten } from 'polished'
import { media } from '../../../theme'
import Calendar from '../../Calendar'
import Module from '../Module'
import dateFormatter from '../../Calendar/dateFormatter'
import renderDay from './renderDay'
import Events from './Events'
const dateFormat = dateFormatter('YmD')

const StyledCalendar = styled(Calendar)`
  border: none;
  text-align: left;
`

const CalendarModule = ({ module, className }) => {
  const [date, setDate] = useState()
  const [events, setEvents] = useState()
  const onChange = ({ target: { value } }) => setDate(value)

  const getCalendar = async () => {
    const response = await fetch('/.netlify/functions/calendar')
    const events = await response.json()
    setEvents(events)
  }

  useEffect(() => {
    getCalendar()
  }, [])

  return (
    <Module module={module} className={className}>
      <StyledCalendar
        onChange={onChange}
        value={date}
        renderDay={props =>
          renderDay({
            events: events?.[dateFormat(props.day)],
            ...props,
          })
        }
      />
      {date && events?.[dateFormat(date)] && (
        <Events events={events[dateFormat(date)]} />
      )}
    </Module>
  )
}

export default CalendarModule
