import React from 'react'
import styled, { keyframes } from 'styled-components'
import { theme } from 'styled-tools'
import { rem } from 'polished'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: ${rem(200)};
  margin: 0 auto;
`

const pulse = keyframes`
  0%, 90%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.4);
  }
`

const duration = 1000
const Dot = styled.div`
  display: block;
  border-radius: 50%;
  background: ${theme('colors.primary')};
  width: 27%;
  height: 0;
  padding-bottom: 27%;
  animation: ${pulse} ${duration}ms ease-in-out infinite both;
  &:nth-child(1)  {
    animation-delay: -${duration / 3}ms;
  }
  &:nth-child(2)  {
    animation-delay: -${duration / 6}ms;
  }
`

const Loader = ({ className }) => (
  <Wrapper className={className}>
    <Dot />
    <Dot />
    <Dot />
  </Wrapper>
)

export default Loader
