const locale = 'sv-SE'
const map = {
  Y: { year: 'numeric' },
  y: { year: '2-digit' },
  M: { month: 'long' },
  m: { month: '2-digit' },
  W: { weekday: 'long' },
  w: { weekday: 'short' },
  D: { day: '2-digit' },
  d: { day: 'numeric' },
  H: { hour: '2-digit' },
  h: { hour: 'numeric' },
  N: { minute: '2-digit' },
  n: { minute: 'numeric' },
  S: { second: '2-digit' },
  s: { second: 'numeric' },
}

const dateFormatter = format => {
  const cfg = format.split('').reduce(
    (acc, key) => ({
      ...acc,
      ...map[key],
    }),
    {}
  )

  return new Intl.DateTimeFormat(locale, cfg).format
}

export default dateFormatter
