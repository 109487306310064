import React from 'react'
import styled from 'styled-components'
import { withProp } from 'styled-tools'
import { rem, tint } from 'polished'
import { media } from '../../theme'

import BlockContent from '../BlockContent'
import Module from './Module'

const StyledModule = styled(Module)`
  h1,
  h2,
  h3 {
    line-height: 1.3;
    margin-bottom: ${rem(16)};
  }
  p a,
  ul a {
    font-weight: 600;
  }
  p + h2 {
    margin-top: ${rem(48)};
  }
  p + h3 {
    margin-top: ${rem(32)};
  }
  p + h4 {
    margin-top: ${rem(24)};
  }
  blockquote {
    margin: ${rem(48)} ${rem(24)};
    font-size: ${rem(24)};
    color: ${withProp('theme.colors.black', tint(0.6))};
  }
  figure {
    margin: ${rem(32)} -${rem(14)};
    figcaption {
      margin: ${rem(6)};
      color: ${withProp('theme.colors.black', tint(0.6))};
      font-size: ${rem(12)};
      ${media('sm')`
        font-size: ${rem(14)};
        margin: ${rem(6)} ${rem(12)};
      `}
    }
  }
  ${media('sm')`
    padding: 0 ${rem(64)};
    figure {
      margin: ${rem(32)} -${rem(64)};
    }
  `}
`

const TextModule = ({ module }) => (
  <StyledModule module={module} align={module.align}>
    {module.pre}
    {module.body && <BlockContent blocks={module.body} />}
  </StyledModule>
)

export default TextModule
