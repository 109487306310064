import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import Root from '../Root'
import SEO from '../SEO'
import Header from '../Header'
import Footer from '../Footer'

const Wrapper = styled.main``

const Layout = ({ meta, children, header }) => (
  <Root>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Amiri:400i|Open+Sans:300,300i,400,600&display=swap"
        rel="stylesheet"
      />{' '}
    </Helmet>
    <SEO {...meta} />
    <Header type={header} />
    <Wrapper>{children}</Wrapper>
    <Footer />
  </Root>
)

export default Layout
