import React, { useState, useMemo, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { theme, ifNotProp, ifProp, withProp } from 'styled-tools'
import { tint, lighten } from 'polished'
import { media } from '../../theme'

import { rem } from 'polished'
import dateFormatter from './dateFormatter'
import {
  getFirstOfMonth,
  getDays,
  getWeekdays,
  getToday,
  previousMonth,
  nextMonth,
} from './dateUtils'

import { Container, Row, Column } from '../Grid'

const monthFormat = dateFormatter('M')
const yearFormat = dateFormatter('Y')

const Wrapper = styled(Container).attrs(() => ({
  gutterWidth: 0,
}))`
  padding: ${rem(18)} ${rem(12)};
  font-weight: 300;
  text-transform: uppercase;
  background: white;
  border-radius: ${rem(2)};
  border: 1px solid ${theme('colors.mediumLight')};
  text-align: center;
`

const StyledColumn = styled(Column)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${rem(12)};
  border-bottom: 1px solid ${theme('colors.mediumLight')};
`

const Label = styled.div`
  width: ${rem(32)};
  height: ${rem(32)};
  line-height: ${rem(32)};
  margin: ${rem(8)} auto;
  ${media('sm')`
    padding: 0 ${rem(6)};
    width: 100%;
  `}
`

const LeftArrow = styled.span`
  ::before {
    content: '';
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    border-top: 1px solid currentColor;
    border-left: 1px solid currentColor;
    transform: rotate(-45deg);
  }
  cursor: pointer;
  padding: 1em;
  margin: -1em 0;
`

const RightArrow = styled(LeftArrow)`
  ::before {
    transform: rotate(135deg);
  }
`

const Day = styled(Label).attrs(({ day }) => ({ children: day.getDate() }))`
  border-radius: 50%;
  cursor: pointer;
  ${ifNotProp(
    'activeMonth',
    css`
      color: ${theme('colors.mediumLight')};
    `
  )}
  ${ifProp(
    'today',
    css`
      background: ${withProp('theme.colors.primary', lighten(0.4))};
    `
  )}
  ${ifProp(
    'active',
    css`
      background: ${theme('colors.primary')};
      color: ${theme('colors.white')};
    `
  )}
  ${ifProp(
    'disabled',
    css`
      color: ${withProp('theme.colors.primaryText', tint(0.9))};
      cursor: auto;
    `
  )}
  ${media('sm')`
    padding: 0 ${rem(6)};
    width: ${rem(32)};
  `}
`

const Calendar = ({
  className,
  name,
  value,
  onChange,
  isDisabled = () => false,
  renderDay = props => <Day {...props} />,
}) => {
  const setDate = useCallback(
    value => (onChange ? onChange({ target: { name, value } }) : undefined),
    [name, onChange]
  )

  const today = getToday()
  const weekdays = useMemo(() => getWeekdays(today), [today])
  const [dateCursor, setDateCursor] = useState(getFirstOfMonth(value || today))
  const days = useMemo(() => getDays(dateCursor), [dateCursor])

  return (
    <Wrapper className={className}>
      <Row>
        <StyledColumn>
          <div>
            {yearFormat(dateCursor)} – {monthFormat(dateCursor)}
          </div>
          <div>
            <LeftArrow onClick={() => setDateCursor(previousMonth)} />
            <RightArrow onClick={() => setDateCursor(nextMonth)} />
          </div>
        </StyledColumn>
      </Row>
      <Row>
        {weekdays.map(day => (
          <Column key={day} size={1 / 7}>
            <Label>{day}</Label>
          </Column>
        ))}
      </Row>
      <Row>
        {days.map(day => (
          <Column key={day.toISOString()} size={1 / 7}>
            {renderDay({
              activeMonth: day.getUTCMonth() === dateCursor.getUTCMonth(),
              today: day.toISOString() === today.toISOString(),
              disabled: isDisabled(day),
              onClick: () => !isDisabled(day) && setDate(day),
              active: value && day.toISOString() === value.toISOString(),
              day,
            })}
          </Column>
        ))}
      </Row>
    </Wrapper>
  )
}
export default Calendar
