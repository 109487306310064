import React from 'react'

import WrappedImage from './WrappedImage'
import { List, ListItem } from './List'
import * as marks from './marks'

const serializers = {
  types: {
    wrappedImage: WrappedImage,
  },
  list: props => <List {...props} />,
  listItem: props => <ListItem {...props} />,
  marks,
}

export default serializers
