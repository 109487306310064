import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { theme, withProp } from 'styled-tools'
import { rem, tint } from 'polished'
import { media } from '../../theme'

import BlockContent from '../BlockContent'
import Module from './Module'

const StyledModule = styled(Module)`
  h1,
  h2,
  h3 {
    line-height: 1.3;
    margin-bottom: ${rem(8)};
  }
  h2 {
    margin-top: ${rem(32)};
    margin-bottom: ${rem(8)};
  }
  p a,
  ul a {
    font-weight: 600;
  }
  p + h2 {
    margin-top: ${rem(48)};
  }
  p + h3 {
    margin-top: ${rem(32)};
  }
  p + h4 {
    margin-top: ${rem(24)};
  }
  blockquote {
    margin: ${rem(48)} ${rem(24)};
    font-size: ${rem(24)};
    color: ${withProp('theme.colors.black', tint(0.6))};
  }
  figure {
    margin: ${rem(32)} -${rem(14)};
    figcaption {
      margin: ${rem(6)};
      color: ${withProp('theme.colors.black', tint(0.6))};
      font-size: ${rem(12)};
      ${media('sm')`
        font-size: ${rem(14)};
        margin: ${rem(6)} ${rem(12)};
      `}
    }
  }
  ${media('sm')`
    padding: 0 ${rem(64)};
    figure {
      margin: ${rem(32)} -${rem(64)};
    }
  `}
`

const Item = styled.article`
  padding: ${rem(36)} 0 ${rem(12)};
  border-bottom: 1px solid ${theme('colors.light')};
  :first-of-type {
    border-top: 1px solid ${theme('colors.primary')};
  }
`

const Price = styled.p`
  font-weight: 700;
  font-size: ${rem(20)};
  margin-bottom: 0;
`

const PriceInfo = styled.span`
  font-weight: 400;
  font-style: italic;
`

const useServiceItems = () => {
  const data = useStaticQuery(graphql`
    query ServiceItemsQuery {
      serviceItems: allSanityServiceItem(
        sort: { order: DESC, fields: _updatedAt }
      ) {
        edges {
          node {
            _id
            price
            priceInfo
            title
            type
            description: _rawDescription
          }
        }
      }
    }
  `)

  return data.serviceItems.edges.map(({ node }) => node)
}

const ServiceItemsModule = ({ module }) => {
  const serviceItems = useServiceItems()
  return (
    <StyledModule module={module} align={module.align}>
      {module.body && <BlockContent blocks={module.body} />}
      {module.groups.map(group => (
        <div key={group._key}>
          <h2>{group.title}</h2>
          {serviceItems
            .filter(item => item.type === group.id)
            .map(item => (
              <Item key={item._id}>
                <h3>{item.title}</h3>
                {item.description && <BlockContent blocks={item.description} />}
                <Price>
                  {item.price}
                  {item.priceInfo && <PriceInfo> {item.priceInfo}</PriceInfo>}
                </Price>
              </Item>
            ))}
        </div>
      ))}
    </StyledModule>
  )
}

export default ServiceItemsModule
