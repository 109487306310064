import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { rem } from 'polished'
import { media } from '../../theme'

import Module from './Module'
import Image from '../Image'
import { Row, Column } from '../Grid'

const query = graphql`
  query Bikes {
    allSanityBike(sort: { fields: [sortValue, title], order: [ASC, ASC] }) {
      nodes {
        slug {
          current
        }
        id
        title
        price
        specifications: _rawSpecifications
        images {
          asset {
            _ref: _id
          }
        }
      }
    }
  }
`

const StyledModule = styled(Module)`
  h2 {
    text-align: center;
    margin-bottom: ${rem(32)};
  }
  h3 {
    margin: ${rem(8)} 0 0;
    line-height: 1.3;
  }
  p {
    font-size: ${rem(16)};
    margin: ${rem(4)} 0;
    font-weight: 600;
    color: ${ifProp(
      { 'module.variant': 'dark' },
      theme('colors.light'),
      theme('colors.medium')
    )};
  }
  a:hover {
    text-decoration: none;
  }

  ${Column} {
    margin-bottom: ${rem(48)};
    ${media('sm')`
      padding: 0 ${rem(16)};
    `}
  }
`

const BikeListModule = ({ module, className }) => {
  const {
    allSanityBike: { nodes },
  } = useStaticQuery(query)
  const bikes = nodes.filter(bike =>
    ['brand', 'type', 'category'].every(
      prop => !module[prop] || module[prop] === bike.specifications[prop]
    )
  )
  return bikes.length ? (
    <StyledModule module={module} className={className} eager>
      <h2>{module.title}</h2>
      <Row as="ul">
        {bikes.map(bike => (
          <Column as="li" key={bike.id} sm={1 / 3}>
            <Link to={`/cyklar/${bike.slug.current}/`}>
              {bike.images.length ? (
                <Image image={bike.images[0].asset._ref} />
              ) : null}
              <h3>
                {bike.specifications.brand} {bike.specifications.name}
              </h3>
              <p>{bike.price}</p>
            </Link>
          </Column>
        ))}
      </Row>
    </StyledModule>
  ) : null
}

export default BikeListModule
