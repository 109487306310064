import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { rem } from 'polished'
import { media } from '../../theme'

export default styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  margin: ${rem(16)} 0;
  background: ${ifProp('inverted', 'transparent', theme('colors.primary'))};
  border: 1px solid;
  border-color: ${ifProp('inverted', theme('colors.primary'), 'transparent')};
  color: ${ifProp('inverted', theme('colors.primary'), theme('colors.white'))};
  opacity: ${ifProp('disabled', 0.5, 1)};
  width: 100%;
  ${media('sm')`
    width: auto;
  `}
`
