import React from 'react'
import styled, { css } from 'styled-components'
import { theme, ifNotProp, ifProp, withProp } from 'styled-tools'
import { rem, tint, lighten } from 'polished'
import { media } from '../../../theme'

const Label = styled.div`
  width: ${rem(32)};
  height: ${rem(32)};
  line-height: ${rem(32)};
  margin: ${rem(8)} auto;
  text-align: center;
  ${media('sm')`
    width: 100%;
    height: ${rem(92)};
    line-height: 1;
    padding-top: ${rem(6)};
    margin: 0;
  `}
`

const Day = styled(Label)`
  border-radius: 50%;
  cursor: pointer;
  ${ifNotProp(
    'activeMonth',
    css`
      color: ${theme('colors.mediumLight')};
    `
  )}
  ${ifProp(
    'today',
    css`
      background: ${withProp('theme.colors.primary', lighten(0.4))};
    `
  )}
  ${ifProp(
    'active',
    css`
      background: ${theme('colors.primary')};
      color: ${theme('colors.white')};
    `
  )}
  ${ifProp(
    'disabled',
    css`
      color: ${withProp('theme.colors.primaryText', tint(0.9))};
      cursor: auto;
    `
  )}
  ${media('sm')`
    position: relative;
    border-radius: ${rem(2)};
    border: 0px solid ${theme('colors.light')};
    padding: ${rem(6)};
  `}
`

const MarkerWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${media('sm')`
    display: none;
  `}
`
const Marker = styled.div`
  width: ${rem(6)};
  height: ${rem(6)};
  flex-grow: 0;
  border-radius: 50%;
  background: ${theme('colors.primary')};
  margin-right: ${rem(3)};
  &:last-child {
    margin-right: 0;
  }
`

const List = styled.ul`
  display: none;
  margin: 0;
  text-align: left;
  color: ${ifProp('active', theme('colors.white'), theme('colors.primary'))};
  ${media('sm')`
    display: block;
  `}
`
const ListItem = styled.li`
  line-height: 1.3;
  margin: ${rem(3)} 0;
  text-transform: none;
  font-size: ${rem(14)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const Markers = ({ count }) => (
  <MarkerWrapper>
    {Array(count)
      .fill()
      .map((_, i) => (
        <Marker key={i} />
      ))}
  </MarkerWrapper>
)

const DayLabel = styled.div`
  ${media('sm')`
    text-align: left;
  `}
`

const renderDay = ({ events, ...props }) => (
  <Day {...props}>
    <DayLabel>{props.day.getDate()}</DayLabel>
    <Markers count={events?.length ?? 0} />
    {events && (
      <List active={props.active}>
        {events.map(event => (
          <ListItem key={event.id}>{event.summary}</ListItem>
        ))}
      </List>
    )}
  </Day>
)

export default renderDay
